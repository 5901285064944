$(function() {

  var dirRtlFlag = false;
  if ($("html").attr('dir') == 'rtl') {
    dirRtlFlag = true;
  }

  $('.hp-box-slider').slick({
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    rtl: dirRtlFlag,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    pauseOnHover: false
  });
  
});
